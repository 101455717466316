<template>
  <nav class="hidden md:flex flex-grow justify-end">
    <li class="flex md:space-x-4 lg:space-x-8 text-blue">
      <ul class="relative">
        <!-- Tools -->
        <button
          class="group"
          :class="{ 'underline underline-offset-2': showToolsMenu }"
          @click="toolsMenu"
        >
          Nos outils
          <i
            class="fa-solid fa-chevron-down group-hover:translate-y-0.5 duration-100"
          ></i>
        </button>
        <div
          v-if="showToolsMenu"
          ref="toolsMenuRef"
          class="absolute -bottom-44 -left-80 rounded-xl shadow-lg shadow-blue-500/30 p-6 w-120 text-sm bg-white border border-gray-300"
        >
          <NavToolsMenuContent />
        </div>
      </ul>
      <ul class="relative">
        <button
          class="group"
          :class="{ 'underline underline-offset-2': showAboutMenu }"
          @click="aboutMenu"
        >
          À propos
          <i
            class="fa-solid fa-chevron-down group-hover:translate-y-0.5 duration-100"
          ></i>
        </button>
        <div
          v-if="showAboutMenu"
          ref="aboutMenuRef"
          class="absolute -bottom-28 -left-24 rounded-xl shadow-lg shadow-blue-500/30 p-6 w-48 text-sm bg-white border border-gray-300"
        >
          <NavAboutMenuContent />
        </div>
      </ul>
    </li>
  </nav>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import NavAboutMenuContent from './NavAboutMenuContent.vue';
import NavToolsMenuContent from './NavToolsMenuContent.vue';
const showToolsMenu = ref(false);
const showAboutMenu = ref(false);
const toolsMenuRef = ref();
const aboutMenuRef = ref();

function toolsMenu() {
  if (showAboutMenu.value) showAboutMenu.value = false;
  showToolsMenu.value = !showToolsMenu.value;
}

function aboutMenu() {
  if (showToolsMenu.value) showToolsMenu.value = false;
  showAboutMenu.value = !showAboutMenu.value;
}

onClickOutside(toolsMenuRef, () => {
  if (showToolsMenu.value) showToolsMenu.value = false;
});

onClickOutside(aboutMenuRef, () => {
  if (showAboutMenu.value) showAboutMenu.value = false;
});
</script>
